import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Scroll from '../components/Scroll';
import Footer from '../components/Footer';
import Social from '../components/Social';
import FeatureTile from '../components/FeatureTile';

import demo1 from '../assets/images/demo-screen-1.jpg';
import demo2 from '../assets/images/demo-screen-2.jpg';
import demo3 from '../assets/images/demo-screen-3.jpg';
import demo4 from '../assets/images/demo-screen-4.jpg';
import demo5 from '../assets/images/demo-screen-5.jpg';

import gplay from '../assets/images/google-play-badge.svg';
import astore from '../assets/images/app-store-badge.svg';
import config from '../../config';
import ContactForm from '../sections/ContactForm';
import SubscribeForm from '../sections/SubscribeForm';
import PhoneDisplay from '../components/PhoneDisplay';
import { Swiper, SwiperSlide } from 'swiper/react';
// import SwiperCore, { Controller } from 'swiper';
import 'swiper/swiper.scss';
// SwiperCore.use([Controller]);

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper/core';
import { Link } from 'gatsby';

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

const isPublished = false;

const demoList = [];
for (let i = 2; i <= 7; i++) {
  demoList.push(require(`../assets/images/demo-screen-${i}.jpg`));
}

const IndexPage = () => {
  return (
    <Layout>
      <Header scrollable/>

      <header className="masthead">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-lg-7 col-sm-auto my-auto">
              <div className="header-content mx-auto">
                <h1 className="mb-5 text-uppercase">
                  A NEW AWESOME STAGE CALLED <b>TARANUI</b> IS COMING TO
                  TRAVELLERS AROUND THE WORLD
                </h1>
                <h6 className="mb-5">
                  Travelling is crossing cultures and sharing distances. Travel
                  stories live around us. Lets share them, create relations and
                  make fun stuff together.
                </h6>
                <Scroll type="id" element="download">
                  <a href="#download" className="btn btn-outline btn-xl">
                    Download Now
                  </a>
                </Scroll>
              </div>
            </div>
            <div className="col-lg-5 col-sm-auto my-auto">
              <PhoneDisplay scale={0.8}>
                <img src={demo1} />
              </PhoneDisplay>
            </div>
          </div>
        </div>
      </header>

      <section className="features" id="features">
        <a name="#features"></a>
        <div className="container">
          <div className="section-heading text-center">
            <h2>Cool Features</h2>
            <p className="text-muted">Explore what you can do with this app!</p>
            <hr />
          </div>
          <div className="row">
            <div className="col-lg-4 my-auto d-flex justify-content-center">
              <PhoneDisplay scale={0.8}>
                <Swiper
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                >
                  {demoList.map(value => (
                    <SwiperSlide key={value}>
                      <img src={value} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </PhoneDisplay>
            </div>
            <div className="col-lg-8 my-auto mx-auto">
              <div className="container-fluid">
                <div className="row">
                  {config.features.map(
                    ({ title, description, icon }, index) => (
                      <FeatureTile
                        title={title}
                        description={description}
                        key={`f${index}`}
                        iconClass={icon}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cta">
        <div className="cta-content">
          <div
            className="container"
            style={{ color: 'white', fontSize: '35px' }}
          >
            <h3 style={{ fontSize: '45px' }}>
              <i>
                Why should travellers search the entire Internet to find travel information?
                <br />
                <br />
                Why should travellers post their personal travel experiences on various platforms?
                <br />
                <br />
                Where can travellers put their future travel goals in one bucketlist?
                <br />
                <br />
              </i>
              <b>Taranui puts this all in one place</b>
            </h3>
            <a
              target="_blank"
              href="https://www.youtube.com/watch?v=pbJyjYW69gY"
              className="btn btn-outline-light btn-xl"
              style={{ marginTop: '29px' }}
            >
              Watch video
            </a>
          </div>
        </div>
        <div className="overlay"></div>
      </section>
      <section
        className="download bg-secondary text-secondary text-center"
        id="download"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <h2 className="section-heading">
                Unwrap what all this app is about!
              </h2>

              <div>
                <h4>
                  We are working immensely hard to make the app available.
                  <br />
                  Stay tuned, it's coming!
                </h4>
                <br />
                <div>
                  <div className="badges">
                    <div className="badge-link">
                      <img src={gplay} alt="" />
                    </div>
                    <div className="badge-link">
                      <img src={astore} alt="" />
                    </div>
                  </div>
                </div>

                <Scroll
                  type="id"
                  element="subscribe"
                >
                  <Link
                    style={{ marginTop: '29px' }}
                    className="btn btn-secondary btn-outline-light btn-xl"
                    to="#subscribe"
                  >
                    Subscribe
                  </Link>
                </Scroll>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact bg-primary" id="contact">
        <div className="container" style={{ color: 'white' }}>
          <h2>Hit Up with Us!</h2>
          <Social />
        </div>
      </section>
      <section className="container" id="subscribe">
        <h2 className="text-center">Subscribe Now</h2>
        <p className="text-center">Sign up for our Taranui newsletter</p>
        <SubscribeForm />
      </section>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
