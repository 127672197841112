import React from 'react';


const PhoneDisplay = ({ children, scale = 1 }) => {
  return (
    <>
      <div
        className="marvel-device iphone8plus"
        style={{ transform: `scale(${scale})` }}
      >
        <div className="top-bar"></div>
        <div className="sleep"></div>
        <div className="volume"></div>
        <div className="camera"></div>
        <div className="sensor"></div>
        <div className="speaker"></div>
        <div className="screen">
          {children}
        </div>
        <div className="home"></div>
        <div className="bottom-bar"></div>
      </div>
    </>
  );
};

PhoneDisplay.propsType = {
  scale: Number
};

export default PhoneDisplay;
