import React from 'react';

const SubscribeForm = () => {
  return (
    <form
      name="subscribe-form"
      onSubmit="submit"
      data-netlify="true"
      method="post"
      data-netlify-honeypot="bot-field"
      className="input-group form-control-lg mb-3"
    >
      <input
        type="email"
        required
        name="email"
        className="form-control form-control-lg site-form"
        placeholder="Email"
      />
      <div className="input-group-append input-group-lg">
        <button
          className="rounded-pill rounded-start btn btn-secondary"
          type="submit"
        >
          Subscribe
        </button>
      </div>
      <input type="hidden" name="form-name" value="subscribe-form" />
    </form>
  );
};

export default SubscribeForm;
