import React from 'react';

const FeatureTile = ({ title, description, iconClass }) => {
  return (
    <div className="col-lg-6">
      <div className="feature-item">
        <i className="icon-screen-smartphone text-primary"></i>
        <div className={iconClass}/>
        <h3>{title}</h3>
        <p className="text-muted">{description}</p>
      </div>
    </div>
  );
};

FeatureTile.prototype = {
  title: String,
  description: String,
  iconClass: String,
};

export default FeatureTile;
